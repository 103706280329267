import styled from 'styled-components';
import {VerticalSpace, HorizontalSpace} from 'components';
import {FaPlay} from 'react-icons/fa';
import moment from 'moment';

const ChapterContainer = styled.div`
	background-color: ${({selected, theme}) => selected?theme.colors.darkGrey:theme.colors.black};
	padding: 10px 20px;
	cursor: pointer;
`;
	// border-bottom: 1px solid ${({theme}) => theme.colors.neutralGrey};

const ChapterTitle = styled.div`
	display: flex;
	align-items: center;
	font-size: 1.2rem;
	line-height: ${({theme}) => theme.fonts.height(1.2)};
	font-weight: ${({theme}) => theme.fonts.weight.bold};
	font-family: ${({theme}) => theme.fonts.family.title};
	color: ${({theme}) => theme.colors.pureWhite};
`;

const ChapterDescription = styled.div`
	font-size: 1rem;
	line-height: ${({theme}) => theme.fonts.height(1)};
	font-weight: ${({theme}) => theme.fonts.weight.normal};
	font-family: ${({theme}) => theme.fonts.family.content};
	color: ${({theme}) => theme.colors.neutralGrey};
`;

const ChapterTime = styled.div`
	font-size: 0.8rem;
	line-height: ${({theme}) => theme.fonts.height(0.8)};
	font-weight: ${({theme}) => theme.fonts.weight.normal};
	font-family: ${({theme}) => theme.fonts.family.content};
	color: ${({theme}) => theme.colors.neutralGrey};
`;

const ChapterCard = ({title, content, time, selected, onClick}) => {
	return (
		<ChapterContainer selected={selected} onClick={() => onClick(time)} >
			<ChapterTime>{moment.duration(time*1000).format(`${time > 60?'hh:mm:ss':'mm:ss'}`, {trim: time > 60?true:false})}</ChapterTime>
			<VerticalSpace count={1} />
			<ChapterTitle>{selected?<FaPlay size={15} color="#00FF00" />:null}<HorizontalSpace count={1} />{title}</ChapterTitle>
			<VerticalSpace count={1} />
			{
				selected
				?
				<ChapterDescription>{content}</ChapterDescription>
				:
				null
			}
		</ChapterContainer>
	)
}

export default ChapterCard;