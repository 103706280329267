const family = {
	title: "'Montserrat', sans-serif",
	content: "'Open Sans', sans-serif"
}

const weight = {
	normal: 400,
	bold: 700,
}

const height = (size) => {
	return `${size*1.3}rem`
}

export default {family, weight, height};