import {useState, useEffect, useRef} from 'react';
import {
	RootContainer,
	PlayerContainer,
	Video,
	ChapterListContainer,
} from './Player.styles';
import captions from './captions.vtt';
import QuizModal from './QuizModal';
import moment from 'moment';
import {Chapter, Modal} from 'components';
import chapters from './Chapters.json';

const CHAPTERS = chapters;

const QUIZ = [
	{
		time: 34,
		question: "[Sample Quiz] What  is covered in today's topic?",
		options: [
			{
				text: "	eInsight™ CRM",
				isRightAnswer: false
			},
			{
				text: "	eUpgrade",
				isRightAnswer: false
			},
			{
				text: "eLoyalty",
				isRightAnswer: true
			},
			{
				text: "	eNgage",
				isRightAnswer: false
			},
		]
	},
	{
		time: 89,
		question: "[Sample Quiz] What  is covered in today's topic?",
		options: [
			{
				text: "eInsight™ CRM",
				isRightAnswer: false
			},
			{
				text: "eLoyalty",
				isRightAnswer: true
			},
			{
				text: "	eConcierge",
				isRightAnswer: false
			},
			{
				text: "	ePLite",
				isRightAnswer: false
			},
		]
	}
]


const Player = () => {
	const [currentTime, setCurrentTime] = useState(0);
	const [quiz, setQuiz] = useState(null)
	const videoPlayer = useRef(null)

	const onChapterClick = (time) => {
		videoPlayer.current.currentTime = time
	}

	const answerCallback = () => {
		videoPlayer.current.play()
		setQuiz(null)
	}

	useEffect(() => {
		videoPlayer?.current?.addEventListener("timeupdate", evt => {
			setCurrentTime(Math.floor(videoPlayer.current.currentTime));

			if(Math.floor(videoPlayer.current.currentTime) === QUIZ[0]?.time) {
				videoPlayer.current.pause()
				setQuiz(QUIZ.shift())
			}
		})
	}, [videoPlayer])

	
	return (
		<RootContainer>
			<PlayerContainer>
				<Video ref={videoPlayer} src="https://firebasestorage.googleapis.com/v0/b/chapterme-enterprise.appspot.com/o/Cendyn%20Training%20_%20Loyalty%20ADMIN-20220222_130352-Meeting%20Recording.mp4?alt=media&token=83bdc7b5-9fd2-47f7-8654-b3f220d323d0">
					<track label="English" kind="captions" srcLang="en" src={captions} default />
				</Video>
			</PlayerContainer>
			<ChapterListContainer>
				{
					CHAPTERS.map((chapter, index) => {
						return(<Chapter {...chapter} selected={currentTime >= chapter.time && currentTime < CHAPTERS[index+1]?.time} onClick={onChapterClick} />)
					})
				}
			</ChapterListContainer>
			{
				quiz
				?
				<Modal close={() => setQuiz(null)} >
					<QuizModal quiz={quiz} answerCallback={answerCallback} />
				</Modal>
				:
				null
			}
		</RootContainer>
	)
}

export default Player