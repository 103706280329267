import {ThemeProvider} from 'styled-components';
import Router from 'navigation';
import theme from 'theme';

function App() {
  return (
    <ThemeProvider theme={theme} >
      <Router />
    </ThemeProvider>
  );
}

export default App;

      // <video src="https://cendyn.sharepoint.com/sites/LinkShare/_layouts/15/download.aspx?SourceUrl=%2Fsites%2FLinkShare%2FShared%20Documents%2FCendyn%20Training%20%5F%20Loyalty%20ADMIN%2D20220222%5F130352%2DMeeting%20Recording%2Emp4&correlationid=1c7be31a-00af-4ad7-bdc6-551f0aa0e552" />