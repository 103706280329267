import {useState, useEffect} from 'react';
import {
	RootContainer,
	Hero,
	HeroContent,
	PageTitle,
	PageCaption,
	ArticleListContainer,
	ArticleListTitle,
	ArticleList,
	ArticleListItem,
	ArticleListItemName,
	ArticleListItemMetaText,
	ArticleListItemImage,
	FolderItem,
	FolderItemImage,
	FolderPath,
} from './Library.styles';
import {TextInput, VerticalSpace} from 'components';
import {useNavigate} from 'react-router-dom';
import Thumbnail from './VideoThumbnail.png'

const LIBRARY_ITEMS = [
	{
		name: "eLoyalty",
		url: "https://theme.zdassets.com/theme_assets/9957355/366948980c6cbacd59da6c2f585b65f89598f6b0.png"
	}
]

const FOLDER_CONTENTS = {
	eLoyalty: {
		name: "Cendyn Training - Loyalty Admin",
		id: "e343Dew_fi"
	}
}

const Article = ({article, onClick, folder}) => {
	return(
		<ArticleList onClick={onClick} >
			{
				folder
				?
				<FolderItem>
					<FolderItemImage src={article.url} />
					<ArticleListItemName>{article.name}</ArticleListItemName>
					<ArticleListItemMetaText>{article.duration}{article.chaptersCount}</ArticleListItemMetaText>
				</FolderItem>
				:
				<ArticleListItem>
					<ArticleListItemImage src={article.url} />
					<VerticalSpace count={2} />
					<ArticleListItemName>{article.name}</ArticleListItemName>
					<ArticleListItemMetaText>{article.duration}{article.chaptersCount}</ArticleListItemMetaText>
				</ArticleListItem>
			}
		</ArticleList>
	)
}

const Library = () => {
	const navigate = useNavigate()
	const [currentFolder, setCurrentFolder] = useState('')

	return (
		<RootContainer>
			<Hero>
				<HeroContent>
					<PageTitle>Cendyn Help</PageTitle>
					<PageCaption>Hi, how can we help?</PageCaption>
					<VerticalSpace count={2} />
					<TextInput placeholder="🔍 Search (Disabled)" onChange={evt => console.log("EVENT", evt.target.value)} />		
				</HeroContent>
			</Hero>
			<ArticleListContainer>
				<ArticleListTitle>Cendyn Training (Demo)</ArticleListTitle>
				<FolderPath>Home > <strong>{currentFolder}</strong></FolderPath>
				<VerticalSpace count={2} />
				{
					!currentFolder
					?
					LIBRARY_ITEMS.map(article => <Article folder={true} onClick={() => setCurrentFolder(article.name)} article={article} />)
					:
					<Article article={{...FOLDER_CONTENTS[currentFolder], url: Thumbnail, chaptersCount: '32 Chapters', duration: "1:52:32 |"}} onClick={() => navigate('/player/'+FOLDER_CONTENTS[currentFolder].id)} />
					
				}
			</ArticleListContainer>
		</RootContainer>
	)
}

export default Library;