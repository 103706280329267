import styled from 'styled-components';

const InputContainer = styled.div`
	padding: 10px 90px 10px 12px;
	border-radius: 7px;
	background-color: ${({theme}) => theme.colors.pureWhite};
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: fit-content;
`;

const Input = styled.input.attrs({type: "text"})`
	border: none;
	outline: none;
	background-color: transparent;
	font-size: 1.2rem;
`;

export const TextInput = ({placeholder, onChange}) => {
	return (
		<InputContainer>
			<Input placeholder={placeholder} onChange={onChange} />
		</InputContainer>
	)
}