import styled from 'styled-components';

const GlassyCover = styled.div`
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	background: rgba(0,0,0,0.7);
	display: flex;
	justify-content: center;
	align-items: flex-start;
	z-index: 999;
`;

const Container = styled.div`
	width: ${({large, maxContent}) => large?'80vw':maxContent?maxContent:'60vw'};
	height: auto;
	max-height: 85vh;
	overflow-y: auto;
	background: ${({theme}) => theme.colors.pureWhite};
	border-radius: 10px;
	padding: 15px;

	@media only screen and (orientation:portrait) {
		width: 90vw;
	}
`;

const Modal = ({children, close, large, maxContent, allowClosing}) => (
	<GlassyCover onClick={() => allowClosing?close():null} >
		<Container large={large} maxContent={maxContent} onClick={e => e.stopPropagation()} >
			{children}
		</Container>
	</GlassyCover>
)

export default Modal;