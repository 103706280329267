import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Routes,
  Navigate,
} from "react-router-dom";
import {
	Player,
	Library,
} from 'screens';
// import {useSelector} from 'react-redux';

const ProtectedRoute = ({children}) => {
	// const {accountId} = useSelector(state => state.user)

	if(true)
		return children

	return <Navigate to="/" replace />
}

const RootRouter = () => {
	return (
		<Router>
			<Routes>
				<Route path='/' element={<ProtectedRoute><Library /></ProtectedRoute>} />
				<Route path='/player/:id' element={<ProtectedRoute><Player /></ProtectedRoute>} />
			</Routes>
		</Router>
	)
}

export default RootRouter;

/* 
<Route path='/instructions' element={<Instructions />} />
<Route path='/interview' element={<Interview />} />
<Route path='/thanks/' element={<Thanks />} />
<Route path='/dashboard' element={<Dashboard />} />
*/