const colors = {
	black: "#000000",
	darkGrey: "#565657",
	pureWhite: "#FFFFFF",
	halfWhite: "#F7F7FF",
	neutralGrey: "#999999",
	lightGrey: "#CCCCCC",
	red: '#d50000',
	green: '#00600f',
	purple: '#7289da',
	blue: '#138DCA',
	red: '#D64045',
	green: "#2A4D14",
}

export default colors;