import {useState} from 'react';
import {
	QuizQuestion,
	QuizOptionsContainer,
	QuizOption,
} from './Player.styles'

const QuizModel = ({quiz, answerCallback}) => {
	const [currentAnswer, setCurrentAnswer] = useState(null)

	const backgroundColorSelector = (index, isRightAnswer) => {
		console.log("BACKGROUND", index, isRightAnswer, currentAnswer)
		if(isRightAnswer && currentAnswer !== null) {
			return 'green';
		}
		else if(index === currentAnswer && !isRightAnswer) {
			return 'red';
		}

		return 'pureWhite';
	}

	const onOptionClick = (isRightAnswer, index) => {
		setCurrentAnswer(index)
		setTimeout(answerCallback, 3000)
	}

	return (
		<>
			<QuizQuestion>{quiz.question}</QuizQuestion>
			<QuizOptionsContainer>
				{
					quiz.options.map((option, index) => <QuizOption background={backgroundColorSelector(index, option.isRightAnswer)} selected onClick={() => onOptionClick(option.isRightAnswer, index)} >{option.text}</QuizOption>)
				}
			</QuizOptionsContainer>
		</>
	);
}

export default QuizModel;