import styled from 'styled-components';

export const RootContainer = styled.div`
	max-width: 100vw;
	max-height: 100vh;
	overflow: hidden;
	background-color: ${({theme}) => theme.colors.black};
	display: flex;
`;

export const PlayerContainer = styled.div`
	flex: 6;
	height: 100vh;
	position: relative;
`;

export const BufferLoadingContainer = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 4000;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const Video = styled.video.attrs({controls: true})`
	width: 100%;
	height: 100vh;
`;

export const ChapterListContainer = styled.div`
	flex: 2;
	height: 100vh;
	background-color: ${({theme}) => theme.colors.black};
	overflow-y: scroll;
`;

export const QuizQuestion = styled.h2`
	color: ${({theme}) => theme.colors.darkGrey};
`;

export const QuizOptionsContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 10px 20px;
`;

export const QuizOption = styled.div`
	border: 1px solid ${({theme}) => theme.colors.neutralGrey};
	border-radius: 100px;
	font-weight: ${({theme}) => theme.fonts.weight.bold};
	padding: 10px 20px;
	margin: 10px;
	cursor: pointer;
	background-color: ${({theme, background}) => theme.colors[background]};
	color: ${({theme, background}) => background === 'pureWhite'?theme.colors.darkGrey:theme.colors.pureWhite};
`;