import styled from 'styled-components';

export const RootContainer = styled.div`
	width: 100%;
	min-height: 100%;
`;

export const Hero = styled.div`
	height: 600px;
	position: relative;
	width: 100%;
	background-color: #DAEDF9;
	background-image: url("https://theme.zdassets.com/theme_assets/9957355/737ba8ca6e2cb1c1f7b632c0f11764be9d8c2727.png");
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
`;

export const HeroContent = styled.div`
	width: 80vw;
	margin: 10px 10vw;
`;

export const PageTitle = styled.h1`
	font-size: 46px;
	font-family: ${({theme}) => theme.fonts.family.title};
	color: ${({theme}) => theme.colors.darkGrey};
`;

export const PageCaption = styled.div`
	font-family: ${({theme}) => theme.fonts.family.content};
	font-size: 20px;
	font-weight: ${({theme}) => theme.fonts.weight.bold};
	color: ${({theme}) => theme.colors.darkGrey};
`;

export const ArticleListContainer = styled.div`
	background-color: ${({theme}) => theme.colors.pureWhite};
	padding:  20px 5vw;
	height: 100vh;
`;

export const ArticleListTitle = styled.h2`	
	font-family: ${({theme}) => theme.fonts.family.content};
	color: ${({theme}) => theme.colors.darkGrey};
`;

export const ArticleList = styled.ul`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	list-style: none;
	padding: 0;
`;


export const ArticleListItem = styled.li`
	margin-bottom: 15px;
	display: flex;
	flex-direction: column;
	align-items: center;
	cursor: pointer;
	background-color: ${({theme}) => theme.colors.halfWhite};
	// padding: 15px;
	border-radius: 10px;
	box-shadow: 5px 5px 25px rgba(0,0,0,0.2);
`;

export const FolderItem = styled(ArticleListItem)`
	flex-direction: row;
	background-color: inherit;
	box-shadow: none;
	border: 1px solid ${({theme}) => theme.colors.lightGrey};
`;


export const ArticleListItemImage = styled.img`
	width:600px;
	height:350px;
`;

export const FolderItemImage = styled(ArticleListItemImage)`
	width: 150px;
	height: auto;
`;

export const ArticleListItemName = styled.div`
	font-size: 22px;
	font-weight: ${({theme}) => theme.fonts.weight.bold};
	padding: 5px;
	color: ${({theme}) => theme.colors.darkGrey};
`;

export const ArticleListItemMetaText = styled.div`
	font-size: 1rem;
	font-weight: ${({theme}) => theme.fonts.weight.bold};
	padding: 5px;
	color: ${({theme}) => theme.colors.neutralGrey};
`;

export const FolderPath = styled.p`
	text-align: left;
	font-size: 20px;
	color: ${({theme}) => theme.colors.darkGrey};
`;

